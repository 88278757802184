import React, { useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ExpertiseItem from "./../components/ExpertiseItem";
import FeaturedHero from "./../components/FeaturedHero";
import ReadOnCTA from "./../components/ReadOnCTA";
import VideoModal from "./../components/VideoModal";
import { CSSTransition } from "react-transition-group";

import ServiceSVG1 from "./../assets/svg/animations/expertise-icon1.inline.svg";
import ServiceSVG2 from "./../assets/svg/animations/expertise-icon2.inline.svg";
import ServiceSVG3 from "./../assets/svg/animations/expertise-icon3.inline.svg";
import ServiceSVG4 from "./../assets/svg/animations/expertise-icon4.inline.svg";
import ServiceSVG5 from "./../assets/svg/animations/expertise-icon5.inline.svg";

export const query = graphql`
  {
    allPrismicIndustriesPage {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            meta_description
            meta_title
            canonical {
              uid
              type
            }
            description {
              html
            }
            read_on_item {
              item_icon
              item_link_title
              item_link {
                uid
              }
              item_content {
                html
              }
              item_title {
                text
              }
            }
            read_on_title {
              text
            }
            body {
              ... on PrismicIndustriesPageBodyServiceBlock {
                id
                primary {
                  primary_content {
                    html
                  }
                  secondary_content {
                    raw
                  }
                  title {
                    text
                  }
                }
                items {
                  thumb {
                    fluid {
                      src
                    }
                  }
                  title: title1 {
                    text
                  }
                  video {
                    embed_url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndustriesPage = (props) => {
  const { location } = props;

  const { uid, data } = props.data.allPrismicIndustriesPage.edges[0].node;
  const items = data.body;
  const meta = {
    descripition: data.meta_description,
    title: data.meta_title,
    uid: data.canonical.uid,
    pageType: data.canonical.type,
  };

  const IMAGES = [
    <ServiceSVG1 />,
    <ServiceSVG2 />,
    <ServiceSVG3 />,
    <ServiceSVG4 />,
    <ServiceSVG5 />,
    <ServiceSVG1 />,
    <ServiceSVG2 />,
    <ServiceSVG3 />,
    <ServiceSVG4 />,
    <ServiceSVG5 />,
    <ServiceSVG1 />,
    <ServiceSVG2 />,
    <ServiceSVG3 />,
    <ServiceSVG4 />,
    <ServiceSVG5 />,
    <ServiceSVG1 />,
    <ServiceSVG2 />,
    <ServiceSVG3 />,
    <ServiceSVG4 />,
    <ServiceSVG5 />,
  ];

  const [video, setVideo] = useState(null);

  return (
    <>
      <Layout location={location}>
        <SEO meta={meta} description={meta.descripition} title={meta.title} uid={meta.uid} pageType={meta.pageType} />
        <div className="page">
          <FeaturedHero
            title={data.title.text}
            description={data.description.html}
            label="Services"
            type="expertise"
            image={require("./../assets/svg/hero-expertise.svg")}
          />
          {items.map((item, index) => {
            return (
              <ExpertiseItem
                {...item}
                setVideo={(url) => setVideo(url)}
                index={index}
                SVGImage={IMAGES[index]}
              />
            );
          })}
          <ReadOnCTA title={data.read_on_title} items={data.read_on_item} />
        </div>
      </Layout>
      <CSSTransition
        in={video !== null}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit
        classNames="modal-animation"
      >
        <VideoModal close={() => setVideo(null)} videoURL={video} />
      </CSSTransition>
    </>
  );
};

IndustriesPage.query = query;

export default withPreview(IndustriesPage);
